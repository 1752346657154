import { useEffect } from 'react';
import { useOktaAuth } from './OktaContext';
import OktaError from './OktaError';

const LoginInit = ({ originalUri, errorComponent, loadingElement = null, onAuthResume }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const ErrorReporter = errorComponent || OktaError;
  useEffect(() => {
    if (oktaAuth) {
      oktaAuth.signInWithRedirect({ originalUri });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]);

  const authError = authState && authState.error;
  const displayError = authError;
  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
};

export default LoginInit;
