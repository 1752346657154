import axios from 'axios';

export function request(options) {
  const axiosRequest = axios(options);

  return axiosRequest
    .then((response) => response.data)
    .catch((err) => {
      let errorData = {
        status: null,
        message: null,
      };

      if (err.message) {
        errorData.status = err.status;
        errorData.message = err.message.message;
      } else {
        // Something happened in setting up the request that triggered an Error
        errorData.message = 'No network connection to the server';
        errorData.status = 500;
      }

      const error = new Error(err);
      error.errorData = errorData;
      throw error;
    });
}
