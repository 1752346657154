import { SelectField } from 'react-admin';

const options = [
  { id: 'user', name: 'User' },
  { id: 'moderator', name: 'Moderator' },
  { id: 'admin', name: 'Admin' },
];

const RoleField = (props) => {
  return <SelectField {...props} choices={options} />;
};

export { RoleField };
