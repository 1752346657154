const getDevicePixelRatio = (roundDpr = true) => {
  let dpr = window.devicePixelRatio || 1;

  if (roundDpr) {
    dpr = Math.ceil(dpr);
  }

  if (dpr <= 0 || isNaN(dpr)) {
    dpr = 1;
  }

  let dprString = dpr.toString();
  if (dprString.match(/^\d+$/)) {
    dprString += '.0';
  }

  return dprString;
};

export default getDevicePixelRatio;
