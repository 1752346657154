import { SelectInput } from 'react-admin';

const options = [
  { id: 'user', name: 'User' },
  { id: 'moderator', name: 'Moderator' },
  { id: 'admin', name: 'Admin' },
];

const RoleInput = (props) => {
  return <SelectInput {...props} choices={options} />;
};

export { RoleInput };
